import React from 'react';
import ResourceSeriesLayout from 'templates/resource-series-layout';
import FamilyCenteredApproachImg from "assets/images/family-centered-approach.jpg";
// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const data = {
        title:"Family-Centered Approach Modules",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus purus in massa tempor nec feugiat.",
        series: true,
        modules:[
            {
                title: "Module 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus purus in massa tempor nec feugiat.",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 1: This resource offers key steps for child welfare agency policymakers to consider when developing a drug testing policy for child welfare practice.",
                link: "/",
                img: "https://ncsacw.acf.hhs.gov/images/pdf-thumbs/fca-practice-module-1.jpg",
                fileSize: "11.5 MB"
            },
            {
                title: "Module 2: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus purus in massa tempor nec feugiat.",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 2: This resource provides practice considerations to help child welfare workers implement drug testing into their daily practice.",
                link: "/",
                img: "https://ncsacw.acf.hhs.gov/images/pdf-thumbs/fca-practice-module-2.jpg",
                fileSize: "10.9 MB"
            },
            {
                title: "Module 3: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus purus in massa tempor nec feugiat.",
                source: "National Center on Substance Abuse and Child Welfare, 2021",
                description: "Brief 2: This resource provides practice considerations to help child welfare workers implement drug testing into their daily practice.",
                link: "/",
                img: "https://ncsacw.acf.hhs.gov/images/pdf-thumbs/fca-practice-module-3.jpg",
                fileSize: "10.9 MB"
            }
        ]
    }

const FamilyCenteredApproachModules = ({location}) => {

    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }

    return (
        <ResourceSeriesLayout
            location={location}
            pathname={thisLocationPathname}
            customCurrentPage="Family-Centered Approach Modules"
            data={data}
            img={FamilyCenteredApproachImg}
            >
                <p>
                    Substance use disorders (SUDs) affect the entire family; they can interfere with a
                    parent’s ability to take care of and bond with a child and can disrupt family health
                    and well-being. Traditional SUD treatment focuses on the individual, despite
                    evidence showing that parents and children are most effectively served through a
                    family-centered treatment approach. A family-centered approach to SUD
                    treatment provides a comprehensive array of clinical treatment and related
                    support services that meet the needs of each member in the family, not only of the
                    individual requesting care. The Family First Prevention Services Act offers a
                    historic opportunity for child welfare agencies and their SUD treatment partners to
                    expand and enhance family-centered interventions.
                </p>
                <p>
                    To help communities move toward family-centered care, the National Center on
                    Substance Abuse and Child Welfare (NCSACW) prepared a series of companion
                    modules on implementing a family-centered approach. This series is designed for
                    state-, county-, and agency-level collaborative partners that are working together
                    to improve systems, services, and outcomes for children and families affected by
                    SUDs. The series includes:
                </p>
                <ul>
                    <li>Module 1: Overview of a Family-Centered Approach and Its Effectiveness</li>
                    <li>Module 2: On the Ground—Family-Centered Practice</li>
                    <li>Module 3: Collaboration To Support Family-Centered Practices at the County and State Level</li>
                </ul>
                <p>
                    NCSACW recognizes that a family-centered approach extends well beyond the
                    SUD treatment system, child welfare system, courts, and mental health services,
                    and includes all other agencies and individuals who interact with and serve families.
                    The work of all partners must reflect an understanding and responsiveness to the
                    fact that parents and children live within the context of a larger family system and
                    that families exist within the context of their community and culture. The cultural
                    influences of race, ethnicity, religion, geography, and customs are considerations to
                    prioritize when implementing a family-centered approach.
                </p>
                <p>
                    NCSACW strives to improve family recovery, safety, and stability by advancing best
                    practices and collaboration among agencies, organizations, and courts working with
                    families affected by substance use and co-occurring mental health disorders and
                    child abuse or neglect. For more information about NCSACW, visit the <a href="/">NCSACW
                    webpage</a> or email us at <a href="mailto:ncsacw@cffutures.org">ncsacw@cffutures.org</a>.
                </p>
        </ResourceSeriesLayout>
    )};

export default FamilyCenteredApproachModules;
