import React from 'react';
import OneColumnContent from "templates/one-column-content-layout";
import {Hero, Breadcrumb} from "components/ui";
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import {FlagCardContainer} from "components/containers/Cards"

//layout for resource detail pages with series
const ResourceSeriesLayout = ({location, data, children, ...rest}) => {
  return (
    <Layout location={location}>
    <SeoComponent title={data.title} />
    <Hero
        img={rest.img}
        color="#f5f5f5"
        >
            <Breadcrumb pathname={rest.pathname} customCurrentPage={rest.customCurrentPage}/>
            <h1 name="main-content" id="main-content" className="usa-hero__heading" aria-label={`Hero: ${data.title}`}>{data.title}</h1>
            {data.description && <p>{data.description}</p> }
        </Hero>
        <div className="padding-top-5">
            <OneColumnContent>
                {children}
            </OneColumnContent>
        </div>
        <div>
            <FlagCardContainer resources={data.modules}
            keyName="module"
            cardColor="bg-white"
            grid="desktop:grid-col-12"
            border="border-2px  border-base-lighter" />
        </div>
    </Layout>

    );
};

export default ResourceSeriesLayout;
